<div class="app-content">
  <a class="skip-to-content-link" tabindex="0" href="javascript:void(0)" [routerLink]="['']"
    (click)="navigateSkipLink('skip-nav')">Skip to main content</a>
  <element-renaissance-header *axLazyElement="headerElementUrl; errorTemplate: error"
    [elementConfig]="headerElementConfig" [helpUrl]="helpUrl$ | async">
  </element-renaissance-header>
  <ng-template #error>
    <div class="header-loading">
      <app-header-loading></app-header-loading>
    </div>
  </ng-template>
  <div class="page-content">
    <router-outlet></router-outlet>
  </div>
  <footer class="footer">
    <p class="heading">2024 © Renaissance Learning. All rights reserved. </p>
  </footer>
</div>
