import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, input, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppInfo } from '../services/app-lookup.service';

@Component({
  selector: 'star-apps-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './star-apps-modal.component.html',
  styleUrl: './star-apps-modal.component.scss'
})
export class StarAppsModalComponent {
  @Output() close = new EventEmitter<void>();
  @Input() showModal = false;
  @Input() allAssessmentApps: AppInfo[] = [];
  @Input() assessmentEnglishApps: AppInfo[] = [];
  @Input() assessmentSpanishApps: AppInfo[] = [];
  @Input() assessmentCbmApps: AppInfo[] = [];
  @Input() saebrsApps: AppInfo[] = [];

  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef;
  public focusableElements: HTMLElement[] = [];
  public focusTrapListener: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showModal'] && changes['showModal'].currentValue) {
      this.initializeFocusTrap();
    }
  }

  ngOnDestroy() {
    if (this.focusTrapListener) {
      document.removeEventListener('keydown', this.focusTrapListener);
    }
  }

  initializeFocusTrap() {
    setTimeout(() => {
      if (!this.modalContainer?.nativeElement) {
        console.warn('Modal container not available.');
        return;
      }

      this.focusableElements = Array.from(
        this.modalContainer.nativeElement.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
      ) as HTMLElement[];

      if (this.focusableElements.length === 0) {
        console.warn('No focusable elements found in modal.');
        return;
      }

      this.focusTrapListener = this.handleFocusTrap.bind(this);
      document.addEventListener('keydown', this.focusTrapListener);
    }, 0);
  }

  handleFocusTrap(event: KeyboardEvent) {
    if (event.key !== 'Tab') return;

    const firstElement = this.focusableElements[0];
    const lastElement = this.focusableElements[this.focusableElements.length - 1];

    if (event.shiftKey) {
      if (document.activeElement === firstElement) {
        event.preventDefault();
        lastElement.focus();
      }
    } else {
      if (document.activeElement === lastElement) {
        event.preventDefault();
        firstElement.focus();
      }
    }
  }

  closeStarModal() {
   this.close.emit();
  }

  hasApps(appInfos: AppInfo[]) {
    if (appInfos) {
      return appInfos.length > 0;
    }
    return false;
  }
}
