import { Env } from "./environment.schema";

export const environment: Env = {
    production: false,
    env: 'development',
    launchdarklyClientId: '664e06fdbe82ff0fbadede12',
    headerConfig: {
        url: 'https://resources-devel.renaissance-golabs.com/scripts/renlearn/customelements/renaissanceheader',
        version: 'RenaissanceHeader-CE/RenaissanceHeader-CE-15.0.205.zip'
      },
    rgpResourceSiteUrl: 'https://resources-zone99.renaissance-go.com',
    helpRedirectBaseUrl: 'https://help2redirect.renaissance.com/redir.asp?c=',
    expiredJwtRedirectUrl: 'https://schools.renaissance.com/',
    launchControlBaseUrl:'https://global-dv.renaissance-golabs.com/launchcontrol/launch/',
    invalidJwtRedirectUrl: 'https://schools.renaissance.com/',
    cbmStudentBaseUrl: 'https://{{zone}}-student.renaissance-golabs.com/cbm-student/home',
    statePortalFloridaUrl: 'https://student.state.dev.renaissance.com/florida?zone={{zone}}',
    studentJourneyJWTPublicKey: `-----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAzFetyN3x5vzr3GmdhguI
    JPXYAU8LVk/FLPU37+LSsGOO+PRB9zPxPZwPLFJIvEszSXgkGCxRpOSMLl7i+ko6
    9AMbcodx76XrszWMScpnpColnfe/o84EQWsvCboucI6b4iohKsaoiO0kI+uGbE5o
    F0jQwhESEMFl445nG3I7y1qA++8Bj7S/dus3AhFmqzh5qhYnaG/poEsJhIsQGrWW
    wf1yXUpfvyG1rXElWail6bvC8nPjP2kyeU+hCMsLvAzFZisCsuKzziSX8oa58vnA
    UujtYSqKTAlQJWZ/6x6Gt4ZssA6tfpmWgGRymm0013rdcXSjV7u6/9Fy1t/8VAfz
    6wIDAQAB
    -----END PUBLIC KEY-----`,
    appNavUrl: 'https://global-dv.renaissance-golabs.com/studentjourneyadapter/AppNav',
    learningJourneyUrl: 'https://global-dv.renaissance-golabs.com/studentjourneyadapter/LearningJourney',
    ldRelayProxyUrl: 'https://student-launch-darkly.dev.renaissance.com'
};
