import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { Language } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class LocalizeService {
  private culture: string = '';
  private region: string = '';
  private country: string = '';
  private featureSet: string = '';

  constructor(
    private translate: TranslateService,
    private userService: UserService
  ) { }

  initializeTranslate() {
    this.culture = this.userService.getCulture();
    this.region = this.userService.getRegion();
    this.country = this.userService.getCountry();
    this.featureSet = this.userService.getFeatureSet();

    this.translate.addLangs([Language.EnUs, Language.EnGb]);
    this.translate.setDefaultLang(Language.EnUs);
    this.translate.use(this.culture);
  }

  stream(key: string): Observable<string> {
    return this.translate.stream(key);
  }

  getCulture() {
    return this.culture;
  }

  getRegion() {
    return this.region;
  }

  getCountry() {
    return this.country;
  }

  getFeatureSet() {
    return this.featureSet;
  }
}
