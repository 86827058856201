import { Injectable } from "@angular/core";
import { UserService } from '../user/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { SchoolYear } from "../../shared/models/school-year.model";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LearningJourneyService {
  public currentSchoolYear: SchoolYear | undefined = undefined;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private storageService: LocalStorageService
  ) { }

  public async getCurrentSchoolYear(): Promise<SchoolYear> {
    let accessToken = this.storageService.getItem('token');

    if (!accessToken) {
      throw new Error('Token missing');
    }
    let headers = new HttpHeaders({
      'access-token': accessToken
    });

    let url = `${this.userService.getLearningJourneyUrl()}/SchoolYear`;

    if (this.currentSchoolYear) {
      return this.currentSchoolYear;
    }

    try {
      const currentSchoolYear = await firstValueFrom(this.http.get<SchoolYear>(url, { headers }));
      this.currentSchoolYear = currentSchoolYear;
      return currentSchoolYear;
    } catch (err: any) {
      let msg = err.message;
      if (err.status === 401) {
        msg ='HTTP Status 401 - Unauthorized';
      }
      throw new Error('Error calling LearningJourneyService.getCurrentSchoolYear - Message: ' + msg);
    }
  }
}
