import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TimelineMonth } from '../shared/models/timeline-month.model';
import { TimelineComponent } from '../timeline/timeline.component';
import { TimelinePickerComponent } from '../pickers/timeline-picker/timeline-picker.component';
import { TimeLineService } from '../services/time-line/time-line.service';

@Component({
  selector: 'my-learning',
  standalone: true,
  imports: [CommonModule, RouterOutlet, TimelineComponent, TimelinePickerComponent],
  templateUrl: './my-learning.component.html',
  styleUrl: './my-learning.component.scss'
})
export class MyLearningComponent implements OnInit {
  // public
  //public schoolYear: SchoolYear | undefined = undefined;
  public schoolYearMonths: TimelineMonth[] = [];
  public showTopNav: boolean = true;
  public previousRoute: string = "";

  activeIndex: number = 0;
  loadReading: boolean = true;
  readingImage: string = "../../assets/reading-icon-active.svg";
  mathImage: string = "../../assets/math-icon-default.svg";

  // private
  public currentMonth: TimelineMonth = {} as TimelineMonth;
  allYear!: TimelineMonth;

  constructor(
    private router: Router,
    private timelineService: TimeLineService
  ) {
    this.allYear = timelineService.currentAllYear as TimelineMonth;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousRoute = this.router.url;
        this.showTopNav = !(this.previousRoute === '/mylearningjourney/all-year');
      }
    });

  }

  async ngOnInit() {

    if (this.schoolYearMonths.length == 0) {
      this.schoolYearMonths = this.timelineService.currentSchoolYearMonths as TimelineMonth[];
    }
    this.timelineService.$selectedMonth.subscribe((month: TimelineMonth) => {
      this.currentMonth = month;
    });

    this.bindToggle();
  }

 

  bindToggle() {
    this.activeIndex = this.router.url.includes('reading') ? 0 : 1;
  }

  toggleActive(index: number) {
    this.activeIndex = index;
    if (index == 0) {
      this.readingImage = "../../assets/reading-icon-active.svg";
      this.mathImage = "../../assets/math-icon-default.svg";
      this.loadReading = true;
      this.router.navigate(['/mylearningjourney/reading'], { queryParams: { month: this.currentMonth.calendarIndex, year: this.currentMonth.year } });
    }
    else {
      this.mathImage = "../../assets/math-icon-active.svg";
      this.readingImage = "../../assets/reading-icon-default.svg";
      this.loadReading = false;
      this.router.navigate(['/mylearningjourney/math'], { queryParams: { month: this.currentMonth.calendarIndex, year: this.currentMonth.year } });
    }
  }

  onMonthChangeEvent(month: TimelineMonth) {
    this.currentMonth = month;
    this.timelineService.$selectedMonth.next(month);

    this.navigate(month);
  }

  navigate(month: any) {
    if (month.label === 'All Year') {
      this.router.navigate(['mylearningjourney/all-year']);
    }
    else if (this.checkSubstringInPreviousRoute("math")) {
      this.router.navigate(['/mylearningjourney/math'], { queryParams: { month: month.calendarIndex, year: month.year } });
      this.activeIndex = 1;
    }
    else if (this.checkSubstringInPreviousRoute("reading")) {
      this.router.navigate(['/mylearningjourney/reading'], { queryParams: { month: month.calendarIndex, year: month.year } });
      this.activeIndex = 0;
    }
    else {
      this.router.navigate(['/mylearningjourney/reading'], { queryParams: { month: month.calendarIndex, year: month.year } });
      this.activeIndex = 0;
    }
  }


  checkSubstringInPreviousRoute(substring: string): boolean {
    return this.previousRoute.includes(substring);
  }
}



