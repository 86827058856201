import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import translationEnGb from './translation-files/en-GB.json';
import translationEnUs from './translation-files/en-US.json';

// This approach locks in the translations at build time preventing issues with cached files
// this precludes some strategies for updating translations without rebuilds
// additional downside: clients always are loading ALL translation content as part of main.js
// Note it requires adding the following config to tsconfig.json:
    // "resolveJsonModule": true,
    // "allowSyntheticDefaultImports": true,
export class JsonFileTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch(lang) {
      case 'en-GB':
        return of(translationEnGb);
      case 'en-US':
      default:
        return of(translationEnUs);
    }
  }
}
