<div class="learning-container">
    <div class="sidebar">

    </div>
    <div class="content">
        <div class="top-nav">
            <div class="buttons">
                <button class="top-nav-button" [ngClass]="{ 'active': activeIndex === 0 }" (click)="toggleActive(0)">
                    <img class="icon" [src]="readingImage" alt="" />
                    <div class="text">
                        Reading
                    </div>
                </button>
                <div class="divider"></div>
                <button class="top-nav-button" [ngClass]="{ 'active': activeIndex === 1 }" (click)="toggleActive(1)">
                    <img class="icon" [src]="mathImage" alt="" />
                    <div class="text">
                        Math
                    </div>
                </button>
            </div>
        </div>
        <reading *ngIf="loadReading"></reading>
        <math *ngIf="!loadReading"></math>
    </div>
</div>