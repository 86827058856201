import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAppsComponent } from '../my-apps/my-apps.component';
import { MyLearningComponent } from '../my-learning/my-learning.component';
import { ApplicationService } from '../services/application/application.service';
import { AppNavItem } from '../shared/app-nav-item-model';
import { ZeroStateLicenseComponent } from '../zero-state-license/zero-state-license.component';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule, MyAppsComponent, MyLearningComponent, ZeroStateLicenseComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
  activeIndex: number = 0;
  loadMyApps: boolean = true;
  licensedApps: AppNavItem[] = [];
  loading = true;

  constructor(
    private applicationService: ApplicationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.licensedApps = await this.applicationService.getLicensedApplications(); 
    this.loading = false;   
  }

  toggleActive(index: number) {         
    this.activeIndex = index;
    if (index == 0) {
      this.loadMyApps = true;
    }
    else {
      this.loadMyApps = false;
    }
  }
}
