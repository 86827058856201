import { CUSTOM_ELEMENTS_SCHEMA, Component, NO_ERRORS_SCHEMA, OnInit, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderElementConfig } from './shared/models/header-element-config';
import { HeaderConfigService } from './services/header-config/header-config.service';
import { LazyElementsModule } from '@angular-extensions/elements';
import { Observable } from 'rxjs';
import { HelpIdService } from './services/help-id/help-id.service';
import { CommonModule, ViewportScroller } from '@angular/common';
import { IdleTimeoutService } from './idel/idle-timeout.service';
import { NgIdleModule } from '@ng-idle/core';
import { UserService } from './services/user/user.service';
import { IdleTimeoutConstants } from './idel/idle-timeout.config';
import { A11yModule } from '@angular/cdk/a11y';
import { HeaderLoadingComponent } from './shared/header-loading/header-loading/header-loading.component';
import { PopUpComponent } from './shared/pop-up/pop-up.component';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LazyElementsModule, CommonModule, A11yModule, HeaderLoadingComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'student-journey';
  public isLoggedIn: Promise<boolean>;
  public headerElementConfig: HeaderElementConfig | null = null;
  public headerElementUrl: string = '';
  public helpUrl$: Observable<string> = new Observable<string>();

  constructor(
    private readonly headerConfigService: HeaderConfigService,
    private readonly helpIdService: HelpIdService,
    private readonly idleTimeoutService: IdleTimeoutService,
    private readonly userService: UserService,
    private viewportScroller: ViewportScroller
  ) { 
    this.isLoggedIn = this.userService.isLoggedIn();
    }

    async ngOnInit() {
      await this.userService.isLoaded();
      this.headerElementConfig = await this.headerConfigService.getConfig();
      this.helpUrl$ = this.helpIdService.helpUrl$;
      this.headerElementUrl = this.headerConfigService.getElementUrl();

      // Idle timeout monitoring
    await this.isLoggedIn;
    IdleTimeoutService.configure({
      timeoutMinutes: this.userService.getTimeoutMinutes(),
      keepaliveIntervalMinutes: this.userService.getKeepAliveIntervalMinutes(),
      keepaliveUrl: this.userService.getZoneUri(),
      logoutUrl: this.userService.getLogoutUri(),
      timeToRespondMinutes: IdleTimeoutConstants.DefaultTimeToRespondMinutes
    });

    this.idleTimeoutService.watch();
    this.idleTimeoutService.onIdleEnd(() => {
      //this.lastFocusedElement?.focus();
    });

    this.idleTimeoutService.onIdleTimeout(() => {
      localStorage.removeItem('token');
    });
    
  }   

  public navigateSkipLink(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

}

