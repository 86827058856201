<div [class]="containerClass">
    <div [class]="componentContainerClass">
        <freckle-skill [subject]="'math'" [freckleSkillResult]="freckleSkillResult"></freckle-skill>
    </div>
    <div *ngIf="starTestData.length > 0" class="component-container">
        <app-star-test [starTestData]="starTestData" [starHeaderText]="starHeaderText"></app-star-test>
        <div *ngIf="selectedMonthPhrase" class="monthly-phrases-box">
            <img src="../../assets/images/monthly-phrases/{{selectedMonthPhrase}}" alt=""
                class="math-practice-image" />
        </div>
    </div>
</div>
