import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { MyAppsComponent } from '../my-apps/my-apps.component';
import { MyLearningComponent } from '../my-learning/my-learning.component';
import { PopUpComponent } from '../shared/pop-up/pop-up.component';
import { ApplicationService } from '../services/application/application.service';
import { AppNavItem } from '../shared/app-nav-item-model';
import { ZeroStateLicenseComponent } from '../zero-state-license/zero-state-license.component';
import { PopUpService } from '../shared/pop-up/pop-up.service';
import { Router, RouterOutlet } from '@angular/router';
import { UserService } from '../services/user/user.service';
import { LearningJourneyService } from '../services/learning-journey/learning-journey.service';
import { TimelineMonth } from '../shared/models/timeline-month.model';
import { TimeLineService } from '../services/time-line/time-line.service';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ZeroStateLicenseComponent, PopUpComponent],
  providers: [PopUpService],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent {
  activeIndex: number = 1;
  popupTitle = 'Check out your Progress!';
  popupData = { previousWeekData: 'Data from the previous week' };
  licensedApps: AppNavItem[] = [];
  loading = true;

  private currentMonth: TimelineMonth = {} as TimelineMonth;
  private window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private applicationService: ApplicationService,
    private popUpService: PopUpService,
    private userService: UserService,
    private timelineService: TimeLineService,
    private router: Router
  ) {
    this.window = this.document.defaultView as Window;
  }

  async ngOnInit(): Promise<void> {
    this.licensedApps = await this.applicationService.getLicensedApplications();
    this.loading = false;
    this.timelineService.$selectedMonth.subscribe((month: TimelineMonth) => {
      this.currentMonth = month;
    });
  }

  toggleActive(index: number) {
    this.popUpService.closePopup();
    this.popUpService.toggleActive(index);
    this.activeIndex = index;
    if (index == 0) {
      this.window.location = this.userService.getHomeUri();
    }
    else {
      this.router.navigate(['/mylearningjourney/reading'], { queryParams: { month: this.currentMonth.calendarIndex, year: this.currentMonth.year } });
    }
  }
}
