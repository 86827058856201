import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() { }

  public refresh(): void {
    if (window) {
      window.location.href = window.location.href;
    }
  }

  public navigate(url: string) {
    if (window) {
      window.location.href = url;
    }
  }
}
