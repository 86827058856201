import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'reading',
  standalone: true,
  imports: [],
  templateUrl: './reading.component.html',
  styleUrl: './reading.component.scss'
})
export class ReadingComponent {
 }
