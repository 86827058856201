<div class="zero-state-container">
  <object class="zero-state-image" type="image/svg+xml" data="../../assets/zero-state-license.svg"
    aria-label="SVG Image">
  </object>
  <div class="zero-state-content">
    <h1>It looks like you don’t have any products.</h1>
    <h1>Please see your teacher to make sure that:</h1>
    <ul>
      <li>You are enrolled in a class this school year</li>
      <li>Your class has products assigned</li>
      <li>Your teacher is assigned to your class</li>
    </ul>
  </div>
</div>