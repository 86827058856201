<div class="main-content">
<div class="popup-overlay" *ngIf="isVisible" (click)="onOverlayClick($event)">
    <div class="popup-content pop-up-box" (click)="onPopupClick($event)">
        <div class="popup-image-container">
            <img src="/assets/images/trophy.png" alt="Popup Image" class="popup-image">
        </div>
        <div class="popup-text-container">
            <div class="popup-header">
                <h3 class="popup-text">{{ title }}</h3>
                <button (click)="close()">
                    <span class="glyph-close2"></span>
                </button>
            </div>
        </div>
    </div>
</div>