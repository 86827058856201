import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import * as jwt from 'jose';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { environment } from '../../../environments/environment';

export interface RenaissanceJWT {
  jti: string;
  exp: number;
  aud: string;
  rid: string;
  clientid: string;
  homeuri: string;
  logouturi: string;
  timeoutminutes: number;
  keepaliveintervalminutes: number;
  /**
   * ISO culture string ex: "en-US"
   */
  culture: string;
  /**
   * ex: "US"
   */
  country: string;
  /**
   * Two letter ISO code for a state/province/region ex: "FL"
   */
  region: string;
  /**
   * Renaissance feature set depending on region, ex: "US"
   */
  featureset: string;
  globalbase: string;
  given_name: string;
  family_name: string;
}

interface JWTPayload {
  exp?: number;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})

export class JwtParserService {
  constructor(private toggleService: FeatureToggleService) { }

  async decode(token: string): Promise<RenaissanceJWT> {
    let decoded: RenaissanceJWT;

    decoded = await jwt.decodeJwt<RenaissanceJWT>(token);
    
    return decoded;
  }

  async isValidAndExpired(token: string) {
    try{
      const key = await jwt.importSPKI(environment.studentJourneyJWTPublicKey, 'RS256')
      const {payload} = await jwt.jwtVerify(token, key);
    } catch(e: any) {
      if(e.code == 'ERR_JWT_EXPIRED')
        return true;
    }

    return false;
  }

  async verify(token: string): Promise<boolean> {
    await this.toggleService.isReady;
    try {
      const key = await jwt.importSPKI(environment.studentJourneyJWTPublicKey, 'RS256')
      const { payload } = await jwt.jwtVerify(token, key);

      const typedPayload = payload as JWTPayload;

      let exp: number = typedPayload?.exp ?? 0;

      return 1000 * exp >= Date.now();

    } catch (e) {
      throw new Error('could not decode payload.');
    } 
   
  }
}
