import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TimelineMonth } from '../../shared/models/timeline-month.model';
import { ngMocks } from 'ng-mocks';

ngMocks.autoSpy('jasmine');

@Component({
  selector: 'app-timeline-picker',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './timeline-picker.component.html',
  styleUrl: './timeline-picker.component.scss'
})
export class TimelinePickerComponent implements OnInit {
  
  @Input() allYear?: TimelineMonth;
  @Input() timelineMonths: TimelineMonth[] = [];
  @Input() selectedMonth?: TimelineMonth;

  @Output() monthChangeEvent = new EventEmitter<TimelineMonth>();

  constructor() { }

  ngOnInit(): void {
    this.timelineMonths = this.timelineMonths.filter(month => month.enabled);

    if (this.allYear) {
      this.timelineMonths.unshift(this.allYear);
    }

    // use the correct instance of the month, found in timelineMonths
    const selectedMonth = this.selectedMonth;
    if (selectedMonth) {
      this.selectedMonth = this.timelineMonths.find(month => month.order === selectedMonth.order)!;
    }
  }

  public onMonthChange(event: any) {
    this.selectedMonth = this.timelineMonths.find(month => month.order === +event.target.value)!;

    this.monthChangeEvent.emit(this.selectedMonth);
  }
}
