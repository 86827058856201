import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { JsonFileTranslateLoader } from "./json-file-translate-loader";
import { LocalizeService } from "./localize.service";

@NgModule({
  imports: [
    // ngx-translate and the loader module
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useClass: JsonFileTranslateLoader,
            deps: [HttpClient]
        }
    })
  ],
  declarations: [
  ],
  providers: [
    LocalizeService
  ],
  exports: [
    TranslateModule,
  ]
})
export class LocalizationModule {
    constructor(
       private localizeService: LocalizeService)
    {
      this.localizeService.initializeTranslate();
    }
  }
