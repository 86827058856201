<!-- The Modal -->
<div id="rl-timeout-modal-container">
    <div id="rl-timeout-modal" class="rl-timeout-modal" aria-modal="true">
      <div id="timeoutModal" class="modal">
        <div class="modal-box">
          <!-- Modal content -->
          <div class="modal-content">
            <div class="modal-title">
              <h2 id="focusable-heading" tabindex="0">Are You Still Working?</h2>
            </div>
            <div class="modal-body">
              <br />
              <span class="glyph glyph-clock"></span>
              <div class="modal-body-text">
                <p role="alert" class="warning-interval-paragraph" [innerHTML]="warningIntervalParagraph"></p>
                <p>Would you like to keep working?</p>
              </div>
            </div>
            <br />
            <div class="modal-buttons-container">
              <div class="modal-buttons">
                <button aria-label="Keep Working" class="modal-button modal-return-btn" type="button"
                  (click)="returnToScreen()">Keep Working</button>
                <button aria-label="Log Out" class="modal-button modal-logout-btn" type="button" (click)="logout()">Log
                  Out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>