import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccomplishmentCardConfig } from '../../../shared/models/accomplishment-card-config.model';
import { Months } from '../../../constants/constants';

@Component({
  selector: 'app-accomplishment-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accomplishment-card.component.html',
  styleUrl: './accomplishment-card.component.scss'
})
export class AccomplishmentCardComponent implements OnInit {
  @Input() cardConfig: AccomplishmentCardConfig = {} as AccomplishmentCardConfig;
  constructor() {}

  ngOnInit() {
    this.configureCardDetails();
  }

  // REVISIT: (once LB-1078 is complete) configure dynamic SVG text instead?
  private configureCardDetails() {
    if (this.cardConfig.subject) this.cardConfig.title = this.cardConfig.title!.replace('{subject}', this.cardConfig.subject);
    if (this.cardConfig.minute) this.cardConfig.title = this.cardConfig.title!.replace('{minute}', this.cardConfig.minute);
    if (this.cardConfig.quantity) this.cardConfig.title = this.cardConfig.title!.replace('{quantity}', this.cardConfig.quantity.toString());
    if (this.cardConfig.month || this.cardConfig.month === 0) this.cardConfig.subtitle = this.cardConfig.subtitle!.replace('{month}', Months[this.cardConfig.month]);
  }
}
