import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {

  private closePopupSource = new Subject<void>();
  private toggleActiveSource = new Subject<number>();

  closePopup$ = this.closePopupSource.asObservable();
  toggleActive$ = this.toggleActiveSource.asObservable();

  closePopup() {
    this.closePopupSource.next();
  }

  toggleActive(index: number) {
    this.toggleActiveSource.next(index);
  }
}