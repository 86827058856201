<div id="skip-nav" tabindex="-1">
    <div *ngIf="!loading">
        <div *ngIf="licensedApps!.length > 0; else noLicensedApps">
            <div class="container">
                <div class="rounded-strip">
                    <button class="btn" [ngClass]="{ 'active': activeIndex === 0 }" (click)="toggleActive(0)">My
                        Apps</button>
                    <button class="btn" [ngClass]="{ 'active': activeIndex === 1 }" (click)="toggleActive(1)">My
                        Learning
                        Journey</button>
                </div>
            </div>
            <my-apps *ngIf="loadMyApps"></my-apps>
            <my-learning *ngIf="!loadMyApps"></my-learning>
        </div>
    </div>

    <ng-template #noLicensedApps>
        <zero-state-license></zero-state-license>
    </ng-template>
</div>