import { Routes } from '@angular/router';
import { AppRoutingGuard } from './app-routing.guard';
import { HomeComponent } from './home/home.component';
import { LogOutComponent } from './home/log-out/log-out.component';
import { MainComponent } from './main/main.component';

export const routes: Routes = [
    { path: 'home', component: MainComponent, canActivate: [AppRoutingGuard] },
    { path: 'logout', component: LogOutComponent, canActivate: [AppRoutingGuard]},
    { path: '**', redirectTo: 'home' }
];
