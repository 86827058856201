import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header-loading',
  standalone: true,
  imports: [],
  templateUrl: './header-loading.component.html',
  styleUrl: './header-loading.component.scss'
})
export class HeaderLoadingComponent implements OnInit {

  baseurl: any; string: any;

  ngOnInit(): void {
    this.baseurl = environment.headerConfig.url;
  }
}
      
