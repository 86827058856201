import { Component, OnInit } from '@angular/core';
import { FreckleSkill } from '../shared/models/freckle-skill';
import { FreckleSkillResult } from '../shared/models/freckle-skill-result';
import { FreckleSkillService } from '../services/freckle-skill/freckle-skill.service';
import { FreckleSkillComponent } from '../shared/components/freckle-skill/freckle-skill.component';
import { CommonModule } from '@angular/common';
import { StarTestComponent } from '../shared/components/star-test/star-test.component';
import { StarTestService } from '../services/freckle-skill/star-test.service';
import { StarTestResponse } from '../services/freckle-skill/star-test.response';
import { ActivatedRoute } from '@angular/router';
import { TimeLineService } from '../services/time-line/time-line.service';
import { TimelineMonth } from '../shared/models/timeline-month.model';

@Component({
  selector: 'app-math',
  standalone: true,
  imports: [CommonModule, FreckleSkillComponent, StarTestComponent],
  templateUrl: './math.component.html',
  styleUrl: './math.component.scss'
})
export class MathComponent implements OnInit {
  containerClass: string = '';
  componentContainerClass: string = '';
  showModal: boolean = false;
  freckleMathSkills: FreckleSkill[] = [];
  freckleSkillResult: FreckleSkillResult | null = null;
  starTestData: StarTestResponse[] = [];
  starHeaderText: string = "I took a Star Math test on";
  monthPhrases: any[] = [
    { id: 1, monthPhraseIcon: 'jan-math.svg' },
    { id: 2, monthPhraseIcon: 'feb-math.svg' },
    { id: 3, monthPhraseIcon: 'mar-math.svg' },
    { id: 4, monthPhraseIcon: 'apr-math.svg' },
    { id: 5, monthPhraseIcon: 'may-math.svg' },
    { id: 6, monthPhraseIcon: 'jun-math.svg' },
    { id: 7, monthPhraseIcon: 'jul-math.svg' },
    { id: 8, monthPhraseIcon: 'aug-math.svg' },
    { id: 9, monthPhraseIcon: 'sep-math.svg' },
    { id: 10, monthPhraseIcon: 'oct-math.svg' },
    { id: 11, monthPhraseIcon: 'nov-math.svg' },
    { id: 12, monthPhraseIcon: 'dec-math.svg' }
  ]



  selectedMonth: number = 1;
  selectedYear: number = 2025;
  selectedMonthPhrase: string = 'jan-math.svg';

  constructor(
    private freckleSkillService: FreckleSkillService,
    private starTestService: StarTestService,
    private timelineService: TimeLineService) { }

  async ngOnInit(): Promise<void> {
    this.starTestData = await this.starTestService.getStarMathTestData();
    this.setContainerClass();

    this.timelineService.$selectedMonth.subscribe(async (month: TimelineMonth) => {
      this.selectedMonth = month.calendarIndex as number;  // To access a specific query param
      this.selectedYear = month.year as number;

      this.selectMonthPhrase();
      this.freckleMathSkills = await this.freckleSkillService.getMathSkillsByMonth(this.selectedMonth, this.selectedYear);
      this.processFreckleSkills(this.freckleMathSkills);
    });
  }
  

  processFreckleSkills(freckleMathSkills: FreckleSkill[]) {
    if (this.freckleMathSkills.length > 0) {
      this.freckleSkillResult = this.freckleSkillService.processFreckleSkills(this.freckleMathSkills);
    } else {
      this.freckleSkillResult = null;
    }
  }
  selectMonthPhrase() {
    let month = this.monthPhrases.find(m => m.id == this.selectedMonth);
    this.selectedMonthPhrase = month?.monthPhraseIcon;
  }
  setContainerClass() {
    if (this.starTestData.length === 0) {
      this.containerClass = 'math-container-zero-state';
      this.componentContainerClass = 'component-container-zero-state';
    }
    else {
      this.containerClass = 'math-container';
      this.componentContainerClass = 'component-container';
    }
  }
}
