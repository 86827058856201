import { Injectable } from "@angular/core";
import { UserService } from '../user/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { SchoolYear } from "../../shared/models/school-year.model";
import { firstValueFrom } from "rxjs";
import { TimelineMonth } from "../../shared/models/timeline-month.model";
import { StudentJourneyData } from "../../shared/models/student-journey-data.model";
import { NavigationEnd, Router, RouterOutlet, ActivatedRoute } from '@angular/router';
import { MyLearningComponent } from "../../my-learning/my-learning.component";
import { ClockService } from "../clock/clock.service";
import { LearningJourneyService } from "../learning-journey/learning-journey.service";
import { TimeLineService } from "../time-line/time-line.service";

@Injectable({
  providedIn: 'root'
})
export class LearningJourneyQueryStateManagementService {

  private schoolYearMonths: TimelineMonth[] = [];

  constructor(
    private route: ActivatedRoute,
    private learningJourneyService: LearningJourneyService,
    private timelineService: TimeLineService
  ) { 

  }

  public async startStateManagement() {
  
    this.route.queryParams.subscribe(async params => {
      const month = params['month'];  // To access a specific query param
      const year = params['year'];

      if(this.schoolYearMonths.length == 0) {
        const schoolYear = await this.learningJourneyService.getCurrentSchoolYear();

        const start = new Date(schoolYear!.startDate);
        const end = new Date(schoolYear!.endDate);
        this.schoolYearMonths = TimeLineService.getTimelineMonths(
          start,
          end
        );
      }

      const selectedSchoolYearMonth = this.schoolYearMonths.find(x => x.year == year && x.calendarIndex == month);
      
      if(selectedSchoolYearMonth) {
        this.timelineService.$selectedMonth.next(selectedSchoolYearMonth);
      }
    });

    await this.timelineService.initTimeline();
  }

  
}
