import { Injectable } from "@angular/core";
import { UserService } from '../user/user.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { SchoolYear } from "../../shared/models/school-year.model";
import { firstValueFrom } from "rxjs";
import { TimelineMonth } from "../../shared/models/timeline-month.model";
import { StudentJourneyData } from "../../shared/models/student-journey-data.model";
import { FreckleSkill } from "../../shared/models/freckle-skill";

@Injectable({
  providedIn: 'root'
})
export class LearningJourneyService {
  public currentSchoolYear: SchoolYear | undefined = undefined;
  private currentStudentData: StudentJourneyData[] = [];

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private storageService: LocalStorageService
  ) { }

  public async getCurrentSchoolYear(): Promise<SchoolYear> {
    let accessToken = this.storageService.getItem('token');

    if (!accessToken) {
      throw new Error('Token missing');
    }
    let headers = new HttpHeaders({
      'access-token': accessToken
    });

    let url = `${this.userService.getLearningJourneyUrl()}/SchoolYear`;

    if (this.currentSchoolYear) {
      return this.currentSchoolYear;
    }

    try {
      const currentSchoolYear = await firstValueFrom(this.http.get<SchoolYear>(url, { headers }));
      this.currentSchoolYear = currentSchoolYear;
      return currentSchoolYear;
    } catch (err: any) {
      let msg = err.message;
      if (err.status === 401) {
        msg ='HTTP Status 401 - Unauthorized';
      }
      throw new Error('Error school year information: ' + msg);
    }
  }

  private getSchoolYears(schoolYearInfo: SchoolYear): string[] {
    let schoolYears: string[] = [];

    if (!schoolYearInfo.startDate || !schoolYearInfo.endDate) {
      throw new Error('Missing schoolYear')
    } else {
      let schoolEndYear: string = schoolYearInfo.endDate.substring(0,4);
      let schoolStartYear: string = schoolYearInfo.startDate.substring(0,4);
      if(schoolEndYear == schoolStartYear) {
        schoolYears.push(schoolStartYear);
      } else {
        schoolYears.push(schoolStartYear);
        schoolYears.push(schoolEndYear)
      }
    }

    return schoolYears;
  }

  public async getStudentJourneyData(): Promise<StudentJourneyData[]> {
    let accessToken = this.storageService.getItem('token');
    let studentId = this.userService.getUserId();
    let schoolYearInfo = await this.getCurrentSchoolYear();
    
    let schoolYears = this.getSchoolYears(schoolYearInfo);

    if (!accessToken) {
      throw new Error('Token missing');
    }
    let headers = new HttpHeaders({
      'Authorization': accessToken
    });

    if (!!this.currentStudentData.length) {
      return this.currentStudentData;
    }

    let currentStudentData : StudentJourneyData[] = [];

    for(let schoolYear of schoolYears) {
      let url = `${this.userService.getStudentJourneyDataApiUri()}schoolyear/${schoolYear}/studentid/${studentId}`;

      try {
        let studentData: StudentJourneyData[] = await firstValueFrom(this.http.get<StudentJourneyData[]>(url, { headers }));
        currentStudentData = [...currentStudentData, ...studentData];
        
      } catch (err: any) {
        let msg = err.message;
        if (err.status !== 200) {
          msg =`HTTP Status ${err.status}`;
        }
        throw new Error('Error retrieving Student Data: ' + msg);
      }
    }

    this.currentStudentData = currentStudentData;

    return this.currentStudentData;
  }
}
