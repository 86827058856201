import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Months } from '../../constants/constants';
import { LearningJourneyGuard } from '../../my-learning/my-learning.guard';
import { TimelineMonth } from '../../shared/models/timeline-month.model';
import { ClockService } from '../clock/clock.service';
import { LearningJourneyService } from '../learning-journey/learning-journey.service';
import { UserService } from '../user/user.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeLineService {

  private allYear: TimelineMonth = {
    order: -1,
    calendarIndex: -1,
    year: this.clockService.now().getFullYear(),
    label: "All Year", // i18n
    enabled: true
  } as TimelineMonth;;


  public schoolYearMonths: TimelineMonth[] = [];
  public $selectedMonth: BehaviorSubject<TimelineMonth> = new BehaviorSubject<TimelineMonth>(this.allYear);

  

  constructor(
    private learningJourneyService: LearningJourneyService,
    private clockService: ClockService) { }

    public get currentSchoolYearMonths(): TimelineMonth[] {
      return this.schoolYearMonths;
    }

    public get currentAllYear(): TimelineMonth {
      return this.allYear;
    }

    public async initTimeline() {
      const schoolYear = await this.learningJourneyService.getCurrentSchoolYear();
  
      const start = new Date(schoolYear!.startDate);
      const end = new Date(schoolYear!.endDate);
  
      if(this.schoolYearMonths.length == 0) {
        this.schoolYearMonths = TimeLineService.getTimelineMonths(
        start,
        end
        );
      }
      
      const currentAndEnabledMonths = this.getCurrentAndEnabledMonths(this.schoolYearMonths, start, end);
      this.schoolYearMonths = currentAndEnabledMonths.EnabledMonths;
      this.$selectedMonth.next(currentAndEnabledMonths.currentMonth);
    }

   /**
   * Produce a list of months given a start and end date
   * @param startDate
   * @param endDate
   * @returns
   */
   public static getTimelineMonths(startDate: Date, endDate: Date): TimelineMonth[] {
    let timeline: TimelineMonth[] = [];

    let startMonth = startDate.getMonth();
    let endMonth = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth();

    for (let i = 0; startMonth <= endMonth; i++, startMonth++) {
      let calendarIndex = startMonth % 12;
      let year = startMonth > 11 ? endDate.getFullYear() : startDate.getFullYear();
      let month = {
        order: i,
        calendarIndex: calendarIndex + 1, // 1-based index (Jan=1, Dec=12)
        year: year,
        label: Months[calendarIndex]
      } as TimelineMonth;
      timeline.push(month);
    }
    return timeline;
  }
  
  /**
   * Mutate and decorate months with curent and enabled
   * @param months
   * @param startDate
   * @param endDate
   */
  public getCurrentAndEnabledMonths(months: TimelineMonth[], startDate: Date, endDate: Date): { currentMonth: TimelineMonth, EnabledMonths: TimelineMonth[] }  {
    let startMonth = startDate.getMonth();
    let endMonth = (endDate.getFullYear() - startDate.getFullYear()) * 12 + endDate.getMonth();
    let monthCursor = endMonth;
    let currentMonth: TimelineMonth = {} as TimelineMonth;
    const now = this.clockService.now();

    for (let i = 0; startMonth <= endMonth; i++, startMonth++) {
      const month = months[i];
      let calendarIndex = startMonth % 12;
      let year = startMonth > 11 ? endDate.getFullYear() : startDate.getFullYear();
      if (now.getMonth() == calendarIndex && now.getFullYear() == year) {
        month.current = true;
        monthCursor = startMonth;
        currentMonth = month;
      }
      month.enabled = startMonth <= monthCursor ? true : false;
    }

    return {currentMonth: currentMonth, EnabledMonths: months};
  }

}
