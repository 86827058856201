<div *ngIf="freckleSkillResult; else noResult">
    <div>
        <div class="freckle-skill-box">
            <div class="freckle-skill-box-header">
                <img src="../../assets/freckle-corkboard-sticker.svg" alt="" class="freckle-skill-corner-image" />
                <h2 class="freckle-skill-section-header"> I practiced <strong>{{freckleSkillResult!.timeSpent}}</strong>
                    in
                    Freckle.</h2>
            </div>
            <div class="freckle-skill-box-subcontainer">
                <div class="freckle-skill-content-skills">
                    <ng-container *ngIf="freckleSkillResult.mastered.skills.length > 0">
                        <div class="freckle-skill-header-row">
                            <img src="../../assets/ready-for-more.svg" alt="" class="freckle-skill-practice-image" />
                            <h3 class="freckle-skill-sub-section-header">{{freckleSkillResult!.mastered!.groupName}}
                            </h3>
                        </div>
                        <ul class="freckle-skill-bullet">
                            <li *ngFor="let skill of freckleSkillResult?.mastered?.skills | slice:0:3">
                                <div [innerHTML]=skill?.displayName></div>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="freckleSkillResult.practicing.skills.length > 0">
                        <div class="freckle-skill-header-row">
                            <img src="../../assets/appropriate-level.svg" alt="" class="freckle-skill-practice-image" />
                            <h3 class="freckle-skill-sub-section-header">{{freckleSkillResult!.practicing!.groupName}}
                            </h3>
                        </div>
                        <ul class="freckle-skill-bullet">
                            <li *ngFor="let skill of freckleSkillResult?.practicing?.skills | slice:0:3">
                                <div [innerHTML]=skill?.displayName></div>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="freckleSkillResult.needHelp.skills.length > 0">
                        <div class="freckle-skill-header-row">
                            <img src="../../assets/helping-hand.svg" alt="" class="freckle-skill-practice-image" />
                            <h3 class="freckle-skill-sub-section-header">{{freckleSkillResult!.needHelp!.groupName}}
                            </h3>
                        </div>
                        <ul class="freckle-skill-bullet">
                            <li *ngFor="let skill of freckleSkillResult?.needHelp?.skills | slice:0:3">
                                <div [innerHTML]=skill?.displayName></div>
                            </li>
                        </ul>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="freckleSkillResult.hasLargeGroup" class="show-all-skill-container">
                <a href="#" (click)="openFreckleSkillModal($event)" class="show-all-skills-link">Show All Skills</a>
            </div>
        </div>
    </div>
    <freckle-skill-modal (close)="closeFreckleSkillModal()" [showModal]="showModal"
        [freckleSkillResult]="freckleSkillResult">
    </freckle-skill-modal>

</div>
<ng-template #noResult>
    <div class="freckle-zero-state-container">
        <img src="../../assets/freckle-corkboard-sticker.svg" alt="Freckle Logo"
            class="freckle-zero-state-corner-image" />

        <h2 class="freckle-zero-state-section-text" tabindex="0">{{subjectMessaging[subject]}}</h2>
        <a class="freckle-zero-state-click freckle-link" (click)="navigate()" target="_blank">Find Practice in Freckle <i
                class="arrow right"></i></a>
    </div>
</ng-template>
