import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, ParamMap, Router } from "@angular/router";
import { FeatureToggleService } from "../services/feature-toggle/feature-toggle.service";
import { LearningJourneyQueryStateManagementService } from "../services/learning-journey-query-state-management/learning-journey-query-state-management.service";

@Injectable({
  providedIn: 'root'
})
export class LearningJourneyGuard {
  private window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private toggleService: FeatureToggleService,
    private learningJourneyQueryStateManagement: LearningJourneyQueryStateManagementService,
  ) {
    this.window = this.document.defaultView as Window;
  }

  /**
   * Ensure that timeline and anything else that is needed to be loaded actually loads before the learning journey app starts
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    await this.learningJourneyQueryStateManagement.startStateManagement();
    return true;
  }

}
