{
  "L10N": {
    "TILE": {
      "STAR-MATH": {
        "TITLE": "Star Maths",
        "IMAGE": "star-math-tile.en-GB.svg",
        "ALT-TEXT": "Star Maths, Red Circle With M"
      }
    },
    "IPRESTRICTION": {
      "TITLE": "Cannot Use {{ APPNAME }}",
      "STUDENT": "You cannot use {{ APPNAME }} at the moment. Ask a teacher for help."
    },
    "CAPACITYCHECK": {
      "TITLE": "Cannot Use {{ APPNAME }}",
      "STUDENT": "You cannot use {{ APPNAME }} at the moment. Ask a teacher for help."
    },
    "STARERROR": {
      "TITLE": "Cannot Use {{ APPNAME }}",
      "MESSAGE": "{{ APPNAME }} is not available at the moment."
    },
    "APPERROR": {
      "SSO": "Your teacher is not ready for you to practise in {{ APPNAME }} yet."
    },
    "LAUNCHERROR": {
      "GENERIC": "is not available at the moment."
    }
  }
}
