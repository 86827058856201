import { Component, Input } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { LaunchControlService } from '../../../services/launch-control/launch-control.service';

@Component({
  selector: 'ar-test',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ar-test.component.html',
  styleUrl: './ar-test.component.scss'
})
export class ArTestComponent {
  launchUrl_AR = environment.launchControlBaseUrl + 'apps_ar';
  @Input() arData: string[] = [];  

  constructor(private launchControlService: LaunchControlService) { }

  navigate() {
    this.launchControlService.launchApp('apps_ar');
  }
}
