import { Routes } from '@angular/router';
import { AppRoutingGuard } from './app-routing.guard';
import { LogOutComponent } from './home/log-out/log-out.component';
import { MainComponent } from './main/main.component';
import { MyLearningComponent } from './my-learning/my-learning.component';
import { ReadingComponent } from './reading/reading.component';
import { MathComponent } from './math/math.component';
import { AllYearComponent } from './my-learning/all-year/all-year.component';
import { LearningJourneyGuard } from './my-learning/my-learning.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'mylearningjourney/reading', pathMatch: 'full' },
  { path: 'mylearningjourney', redirectTo: 'mylearningjourney/reading', pathMatch: 'full' },
  {
    path: '', component: MainComponent, canActivate: [AppRoutingGuard, LearningJourneyGuard],
    children: [
      {
        path: 'mylearningjourney', component: MyLearningComponent, canActivate: [AppRoutingGuard],
        children: [
          { path: 'reading', component: ReadingComponent, canActivate: [AppRoutingGuard] },
          { path: 'math', component: MathComponent, canActivate: [AppRoutingGuard] },
          { path: 'all-year', component: AllYearComponent, canActivate: [AppRoutingGuard] }
        ]
      }]
  },
  { path: 'logout', component: LogOutComponent, canActivate: [AppRoutingGuard] },
  { path: '**', redirectTo: 'mylearningjourney' }
];
