import { Component } from '@angular/core';

@Component({
  selector: 'my-learning',
  standalone: true,
  imports: [],
  templateUrl: './my-learning.component.html',
  styleUrl: './my-learning.component.scss'
})
export class MyLearningComponent {

}
