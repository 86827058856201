import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HeaderElementConfig } from '../../shared/models/header-element-config';
import { environment } from '../../../environments/environment';
import { StudentAppNavConfig } from '../../shared/models/app-nav-config.model';
import { ApplicationService } from '../application/application.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderConfigService {

  constructor(
    @Inject(DOCUMENT) private document: any,
    private applicationService: ApplicationService,
    private userService: UserService,
  ) { }

  public async getConfig(): Promise<HeaderElementConfig> {
    let studentAvailableApps = await this.applicationService.getLicensedApplications();
    let isDegradedNav = await this.applicationService.isDegradedMode();

    let headerAppNavConfig: StudentAppNavConfig= {
      studentAvailableApps: studentAvailableApps,
      launchControlBaseUrl: environment.launchControlBaseUrl,
      isDegraded: isDegradedNav,
      homeuri: this.userService.getHomeUri()
    };

    // required config
    let headerElementConfig: HeaderElementConfig = {
      logoutUrl: 'logout',
      userName: this.userService.getFullName(),
      baseUrl: environment.headerConfig.url,
      version: environment.headerConfig.version,
      hideUserNameDropdown: false,
      culture: this.userService.getCulture(),
      resourceSiteUrl: environment.rgpResourceSiteUrl,
      launchControlBaseUrl : environment.launchControlBaseUrl,
      accessToken: '',
    };

    headerElementConfig.supportLargerScreenWidth = true;

    // nav menu
    headerElementConfig.studentAppNav = headerAppNavConfig;
    
    return headerElementConfig;
  }

  public getElementUrl(): string {
    let headerElementUrl = `${environment.headerConfig.url}/main.js`;
    return headerElementUrl;
  }
}
