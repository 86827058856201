<div class="text-container">
    <h1 class="text-group"><span class="text-item heading-text">Welcome,</span>
        <span class="name-text">{{userName}}</span>
        <span class="heading-text">!</span>
        <span class="text-item heading-text">Let's</span>
        <span class="text-item heading-text">learn together.</span>
    </h1>
</div>

<div class="my-app-parnet-container">
  <div class="my-app-child-container">
    <div class="section">
        <h2 class="section-header">Practice</h2>
        <div class="box-row">
            <div class="box">
                <div class="icon">📚</div>
                <div class="text">AR</div>
            </div>
            <div class="box">
                <div class="icon">🖋️</div>
                <div class="text">Freckle</div>
            </div>
            <div class="box">
                <div class="icon">📖</div>
                <div class="text">myON</div>
            </div>
        </div>
        <div class="divider"></div>
    </div>

    <div class="section">
        <h2 class="section-header">Assessment</h2>
        <div class="box-row">
            <div class="box">
                <div class="icon">⭐</div>
                <div class="text">Star Assessments</div>
            </div>
        </div>
    </div>
  </div>
</div>
