import { CommonModule } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { PopUpService } from './pop-up.service';

@Component({
  selector: 'app-pop-up',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pop-up.component.html',
  styleUrl: './pop-up.component.scss'
})
export class PopUpComponent implements OnInit {
  @Input() title: string = 'Popup Title';
  @Input() data: any;
  popupMessage: string = ''; 
  popupClosedDate: Date | null = null;
  isVisible: boolean = false;

  constructor(private popUpService: PopUpService) {}

  ngOnInit(): void {
    const today = new Date();
    const currentDay = today.getDay(); 
    
    if (currentDay === 1) {

      if (this.popupClosedDate && this.isSameWeek(today, this.popupClosedDate)) {
        return; 
      }
      if (this.data && this.data.previousWeekData) {
        this.popupMessage = this.data.previousWeekData;
        this.open(); 
      }
    }
    this.popUpService.closePopup$.subscribe(()=>{
      this.close();
    })
  }

  open(): void {
    this.isVisible = true;
  }

  close(): void {
    this.popupClosedDate = new Date();
    this.isVisible = false;
  }

  onOverlayClick(event: MouseEvent) {
    const popupContent = document.querySelector('.popup-overlay');
    if (popupContent && !popupContent.contains(event.target as Node)) {
      this.close();
    }
  }

  onPopupClick(event: MouseEvent) {
    event.stopPropagation();
  }

  private isSameWeek(date1: Date, date2: Date): boolean {
    const startOfWeek1 = this.getStartOfWeek(date1);
    const startOfWeek2 = this.getStartOfWeek(date2);

    return startOfWeek1.getTime() === startOfWeek2.getTime();
  }

  private getStartOfWeek(date: Date): Date {
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const startOfWeek = new Date(date.setDate(diff));
    startOfWeek.setHours(0, 0, 0, 0);
    return startOfWeek;
  }

}
