import { Component } from '@angular/core';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'my-apps',
  standalone: true,
  imports: [],
  templateUrl: './my-apps.component.html',
  styleUrl: './my-apps.component.scss'
})
export class MyAppsComponent {
  constructor(private userService: UserService) { }
  userName: string | null = '';

  ngOnInit(): void {
    this.userName = this.userService.getFirstName();
  }
}
