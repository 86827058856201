import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { AppNavItem } from '../shared/app-nav-item-model';
import { AppSection, AppSubSection } from '../constants/constants';
import { AppInfo, AppLookupService } from '../services/app-lookup.service';
import { CommonModule } from '@angular/common';
import { StarAppsModalComponent } from '../star-apps-modal/star-apps-modal.component';

@Component({
  selector: 'my-apps',
  standalone: true,
  imports: [CommonModule, StarAppsModalComponent],
  templateUrl: './my-apps.component.html',
  styleUrl: './my-apps.component.scss'
})
export class MyAppsComponent implements OnInit {
  showModal: boolean = false;
  userName: string | null = '';
  practiceApps: AppInfo[] = [];
  allAssessmentApps: AppInfo[] = [];
  saebrsApps: AppInfo[] = [];
  assessmentEnglishApps: AppInfo[] = [];
  assessmentSpanishApps: AppInfo[] = [];
  assessmentCbmApps: AppInfo[] = [];
  @Input() licensedApps: AppNavItem[] = [];

  constructor(
    private userService: UserService,
    private appLookupService: AppLookupService) { }

  ngOnInit(): void {
    let appCodes: string[] = [];
    this.userName = this.userService.getFirstName();
    appCodes = this.licensedApps.map(x => x.appCode) as string[];
    this.practiceApps = this._getAppInfoForSectionByTags(appCodes, AppSection.Practice).sort(this._sortByAppOrder);
    this.allAssessmentApps = this._getAppInfoForSectionByTags(appCodes, AppSection.Assessment);
    this.saebrsApps = this._getAppInfoForSectionByTags(appCodes, AppSection.Saebrs).sort(this._sortByAppOrder);

    this.assessmentEnglishApps = this.allAssessmentApps.filter(app => app.appSubSection === AppSubSection.English).sort(this._sortByAppOrder);
    this.assessmentSpanishApps = this.allAssessmentApps.filter(app => app.appSubSection === AppSubSection.Spanish).sort(this._sortByAppOrder);
    this.assessmentCbmApps = this.allAssessmentApps.filter(app => app.appSubSection === AppSubSection.CBM).sort(this._sortByAppOrder);
  }

  private _getAppInfoForSectionByTags = (appTags: string[], appSection: AppSection): AppInfo[] =>
    appTags.map(appTag => this.appLookupService.getAppInfo(appTag))
      .filter(appInfo => !!appInfo)
      .map(appInfo => ({ ...appInfo, launchUrl: this.appLookupService.transformLaunchUrlIfNeeded(appInfo.launchUrl) }))
      .filter(appInfo => appInfo.appSection === appSection)
      .filter((appInfo, index, self) => index === self.findIndex((x) => (x.appsTag === appInfo.appsTag)));

  private _sortByAppOrder = (a: AppInfo, b: AppInfo) => a.order - b.order;

  hasApps(appInfos: AppInfo[]) {
    if (appInfos) {
      return appInfos.length > 0;
    }
    return false;
  }

  openStarModal(): void {
    this.showModal = true;
  }

  closeStarModal(): void {
    this.showModal = false;
  }  
}
