import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSection, AppSubSection, StarCBMToken, StatePortalFloridaToken } from '../constants/constants';
import { environment } from '../../environments/environment';
import { UserService } from './user/user.service';
// import { ConfigService } from './config.service';

export type ScreenReaderLanguage = "en" | "es";

export interface AppInfo {
  /**
   * Order in the indvidual app section
   */
  order: number;
  /**
   * Apps tag like "APPS_AR"
   */
  appsTag: string;
  /**
   * Name of the tile svg
   */
  appSvg: string;
  /**
   * Title of the app to be read by screen reader
   */
  appTitle: string;
  /**
   * Alt-text for app to be read by screen reader
   */
  appAltText: string;
  /**
   * App section for the tile to be placed
   */
  appSection: AppSection;
  /**
   * App sub section for the tile to be placed
   */
  appSubSection: AppSubSection;
  /**
   * Language to read for the screen reader
   */
  language: ScreenReaderLanguage;
  /**
   * GET based launch url for the app
   */
  launchUrl: string;
  /**
   * When true, indicates the app supports email subscriptions via Home Connect
   */
  hasEmailSubscription: boolean;
  /**
   * When true, the tile will use routerLink instead of href
   */
  useRouterLink?: boolean;
  /**
   * When true, app has separate endpoint for degraded launches
   */
  hasDegradedEndpoint: boolean;
  /**
   * Temporary. Used to toggle new SVGs
   */
  useNewSvg?: boolean;

  /**
   * Set mouse hover border color
   */
  color?: string;

}

export interface LicenseInformation {
  applicationId: number,
  appOrder: number,
  applicationTag: string,
  color: string,
  secondColor: string,
  groupName: string,
  subName: string,
  svg: string,
  toolTip: string,
  launchRoute: string,
  title: string,
  logo: string
}

@Injectable({
  providedIn: 'root',
})
export class AppLookupService {

  constructor(
    @Inject(TranslateService) private translateService: TranslateService,
    private userService: UserService
    ) { }

  public getAppInfo(appId: string): AppInfo {
    // let isDegraded = this._configService.isDegradedMode();

    let appInfo = this.AppInfoLookup[appId];

    // localize relevant appInfo fields per call:
    appInfo.appTitle = this.translateService.instant(appInfo.appTitle);
    appInfo.appSvg = this.translateService.instant(appInfo.appSvg);
    appInfo.appAltText = this.translateService.instant(appInfo.appAltText);

    // if (isDegraded && appInfo.hasDegradedEndpoint) {
    //   appInfo.launchUrl += "Degraded";
    // }

    return appInfo
  }

  // Separate AppInfo for Freckle since it must be reused by multiple appTags in AppInfoLookup
  private freckleAppInfo: AppInfo = {
    order: 1,
    appsTag: 'APPS_FRECKLE',
    appSvg: 'freckle-tile.svg',
    appTitle: 'Freckle',
    appAltText: 'Freckle',
    appSection: AppSection.Practice,
    appSubSection: AppSubSection.None,
    language: "en",
    launchUrl: '{{homeUri}}launch/freckle',
    hasEmailSubscription: false,
    useRouterLink: false,
    hasDegradedEndpoint: true,
    color: '#c7378c'
  }

  private AppInfoLookup: { [key: string]: AppInfo } = {
    APPS_UNKNOWN: {
      order: 99,
      appsTag: 'APPS_UNKNOWN',
      appSvg: 'not-found.svg',
      appTitle: 'Unknown App',
      appAltText: 'Unknown App',
      appSection: AppSection.Unknown,
      appSubSection: AppSubSection.None,
      language: "en",
      launchUrl: '{{homeUri}}',
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    // Star Custom, for app info reference only (no student tile)
    APPS_STARC: {
      order: 99,
      appsTag: 'APPS_STARC',
      appSvg: 'not-found.svg',
      appTitle: 'Star Custom',
      appAltText: 'Star Custom',
      appSection: AppSection.Unknown,
      appSubSection: AppSubSection.None,
      language: "en",
      launchUrl: '{{homeUri}}',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    // Practice
    APPS_AM: {
      order: 0,
      appsTag: 'APPS_AM',
      appSvg: 'not-found.svg',
      appTitle: 'Accelerated Math',
      appAltText: 'Accelerated Math',
      appSection: AppSection.Unknown,
      appSubSection: AppSubSection.None,
      language: "en",
      launchUrl: '{{homeUri}}',
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_AR: {
      order: 0,
      appsTag: 'APPS_AR',
      appSvg: 'ar-tile.svg',
      appTitle: 'AR',
      appAltText: 'Accelerated Reader',
      appSection: AppSection.Practice,
      appSubSection: AppSubSection.None,
      language: "en",
      launchUrl: '{{baseUri}}sdp/',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false,
      color: '#df6228'
    },
    APPS_AR360: {
      order: 0,
      appsTag: 'APPS_AR360', // TODO we don't have a separate tile for AR360 but it's also going away? I have a note out to UX about this
      appSvg: 'ar-tile.svg',
      appTitle: 'AR',
      appAltText: 'Accelerated Reader',
      appSection: AppSection.Practice,
      appSubSection: AppSubSection.None,
      language: "en",
      launchUrl: '{{baseUri}}sdp/',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false,
      color: '#df6228'
    },
    APPS_MYON: {
      order: 2,
      appsTag: 'APPS_MYON',
      appSvg: 'myON-tile.svg',
      appTitle: 'myON',
      appAltText: 'myON',
      appSection: AppSection.Practice,
      appSubSection: AppSubSection.None,
      language: "en",
      launchUrl: '{{homeUri}}launch/myon',
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: true,
      color: '#8383f3'
    },
    APPS_LALILO: {
      order: 2,
      appsTag: 'APPS_LALILO',
      appSvg: 'lalilo-tile.svg',
      appTitle: 'Lalilo',
      appAltText: 'Lalilo',
      appSection: AppSection.Practice,
      appSubSection: AppSubSection.None,
      language: "en",
      launchUrl: '{{homeUri}}launch/lalilo',
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: true,
      color: '#34c3c4'
    },
    // Star English
    APPS_SEL: {
      order: 0,
      appsTag: 'APPS_SEL',
      appSvg: 'star-early-literacy-tile.svg',
      appTitle: 'Star Early Literacy',
      appAltText: 'Star Early Literacy, Yellow Circle with E L',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.English,
      language: "en",
      launchUrl: '{{homeUri}}star/APPS_SEL',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_SR: {
      order: 1,
      appsTag: 'APPS_SR',
      appSvg: 'star-reading-tile.svg',
      appTitle: 'Star Reading',
      appAltText: 'Star Reading, Blue Circle with R',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.English,
      language: "en",
      launchUrl: '{{homeUri}}star/APPS_SR',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_SM: {
      order: 2,
      appsTag: 'APPS_SM',
      appSvg: 'L10N.TILE.STAR-MATH.IMAGE',
      appTitle: 'L10N.TILE.STAR-MATH.TITLE',
      appAltText: 'L10N.TILE.STAR-MATH.ALT-TEXT',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.English,
      language: "en",
      launchUrl: '{{homeUri}}star/APPS_SM',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_SRLEARNOSITYCALIBRATION: {
      order: 3,
      appsTag: 'APPS_SRLEARNOSITYCALIBRATION',
      appSvg: 'new-test.svg',
      appTitle: 'New Test',
      appAltText: 'New Test',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.English,
      language: 'en',
      launchUrl: '{{homeUri}}star/APPS_SRLEARNOSITYCALIBRATION',
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_SAEBRS: {
      order: 3,
      appsTag: 'APPS_SAEBRS',
      appSvg: 'mySaebrs-tile.svg',
      appTitle: 'mySaebrs',
      appAltText: 'mySaebrs',
      appSection: AppSection.Saebrs,
      appSubSection: AppSubSection.None,
      language: 'en',
      launchUrl: '{{homeUri}}star/APPS_SAEBRS',
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },

    // Star Spanish
    APPS_SELS: {
      order: 0,
      appsTag: 'APPS_SELS',
      appSvg: 'star-early-literacy-spanish-tile.svg',
      appTitle: 'Star Early Literacy Spanish',
      appAltText: 'Star Early Literacy Español, Green Rectangle with E L E',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.Spanish,
      language: 'es',
      launchUrl: '{{homeUri}}star/APPS_SELS',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_SRS2: {
      order: 1,
      appsTag: 'APPS_SRS2',
      appSvg: 'star-reading-spanish-tile.svg',
      appTitle: 'Star Reading Spanish',
      appAltText: 'Star Reading Español, Purple Rectangle with R E',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.Spanish,
      language: 'es',
      launchUrl: '{{homeUri}}star/APPS_SRS2',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_SMS: {
      order: 2,
      appsTag: 'APPS_SMS',
      appSvg: 'star-math-spanish-tile.svg',
      appTitle: 'Star Math Spanish',
      appAltText: 'Star Math Español, Brown Rectangle With M E',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.Spanish,
      language: 'es',
      launchUrl: '{{homeUri}}star/APPS_SMS',
      hasEmailSubscription: true,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    // Star CBM
    APPS_CBM: {
      order: 0,
      appsTag: 'APPS_CBM',
      appSvg: 'star-cbm-tile.svg',
      appTitle: 'Star CBM',
      appAltText: 'Star CBM, Orange Diamond with C',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.CBM,
      language: 'en',
      launchUrl: StarCBMToken,
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    // Star Phonics
    APPS_PHONICS: {
      order: 1,
      appsTag: 'APPS_PHONICS',
      appSvg: 'star-phonics-tile.svg',
      appTitle: 'Star Phonics',
      appAltText: 'Star Phonics, Light Purple Diamond with P',
      appSection: AppSection.Assessment,
      appSubSection: AppSubSection.CBM,
      language: 'en',
      launchUrl: '{{homeUri}}launch/starphonics',
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: true
    },
    // State Portal
    APPS_FLSSP: {
      order: 0,
      appsTag: 'APPS_FLSSP',
      appSvg: 'state-test-tile-fl.svg',
      appTitle: 'FAST Tests',
      appAltText: 'FAST Tests, White Circle with F L',
      appSection: AppSection.StatePortal,
      appSubSection: AppSubSection.None,
      language: 'en',
      launchUrl: StatePortalFloridaToken,
      hasEmailSubscription: false,
      useRouterLink: false,
      hasDegradedEndpoint: false
    },
    APPS_FRSCIENCE: this.freckleAppInfo,
    APPS_FRSOCSTU: this.freckleAppInfo,
    APPS_FRELA: this.freckleAppInfo,
    APPS_FRMATH: this.freckleAppInfo,
  }

  public transformLaunchUrlIfNeeded(urlToTransform: string): string {
    if (urlToTransform.includes(StarCBMToken)) {
      return urlToTransform.replace(StarCBMToken, environment.cbmStudentBaseUrl).replace('{{zone}}', this.userService.getZone());
    } else if (urlToTransform.includes(StatePortalFloridaToken)) {
      return urlToTransform.replace(StatePortalFloridaToken, environment.statePortalFloridaUrl).replace('{{zone}}', this.userService.getZone());
    } else if (urlToTransform.includes("{{baseUri}}")) {
      return urlToTransform.replace("{{baseUri}}", this.userService.getRgpBaseUrl())
    } else if (urlToTransform.includes("{{homeUri}}")) {
      return urlToTransform.replace("{{homeUri}}", this.userService.getHomeUri())
    }
    return urlToTransform;
  }
}
