{
  "L10N": {
    "TILE": {
      "STAR-MATH": {
        "TITLE": "Star Math",
        "IMAGE": "star-math-tile.svg",
        "ALT-TEXT": "Star Math, Red Circle With M"
      }
    },
    "IPRESTRICTION": {
      "TITLE": "Can't Use {{ APPNAME }}",
      "STUDENT": "You can't use {{ APPNAME }} right now. Ask a teacher for help.",
      "TEACHER": "An administrator used IP Restrictions to prevent student access to one or more products. If you have questions, ask the administrator."
    },
    "CAPACITYCHECK": {
      "TITLE": "Can't Use {{ APPNAME }}",
      "STUDENT": "You can't use {{ APPNAME }} right now. Ask a teacher for help.",
      "TEACHER": "The current class is full. Please contact your administrator."
    },
    "STARERROR": {
      "TITLE": "Can't Use {{ APPNAME }}",
      "MESSAGE": "{{ APPNAME }} is not available right now."
    },
    "APPERROR": {
      "SSO": "Your teacher is not ready for you to practice in {{ APPNAME }} yet.",
      "UNKNOWN": "Something went wrong with the application. Try again later."
    },
    "LAUNCHERROR": {
      "GENERIC": "is not available right now.",
      "CAPACITY": "School is at capacity. Please contact an administrator.",
      "UNKNOWN": "Something went wrong with the application. Try again later."
    }
  }
}
