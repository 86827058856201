import { CommonModule } from '@angular/common';
import { MathComponent } from '../math/math.component';
import { ReadingComponent } from '../reading/reading.component';
import { Component, OnInit } from '@angular/core';
import { LearningJourneyService } from '../services/learning-journey/learning-journey.service';
import { SchoolYear } from '../shared/models/school-year.model';

@Component({
  selector: 'my-learning',
  standalone: true,
  imports: [CommonModule, MathComponent, ReadingComponent],
  templateUrl: './my-learning.component.html',
  styleUrl: './my-learning.component.scss'
})
export class MyLearningComponent implements OnInit {
  // public
  public schoolYear: SchoolYear | undefined = undefined;
  activeIndex: number = 0;
  loadReading: boolean = true;
  readingImage: string = "../../assets/reading-icon-active.svg";
  mathImage: string = "../../assets/math-icon-default.svg";

  constructor(
    private learningJourneyService: LearningJourneyService
  ) { }

  async ngOnInit() {
    this.schoolYear = await this.learningJourneyService.getCurrentSchoolYear();

    // just log for now
    console.log(this.schoolYear);
  }

  toggleActive(index: number) {
    this.activeIndex = index;
    if (index == 0) {
      this.readingImage = "../../assets/reading-icon-active.svg";
      this.mathImage = "../../assets/math-icon-default.svg";
      this.loadReading = true;
    }
    else {
      this.mathImage = "../../assets/math-icon-active.svg";
      this.readingImage = "../../assets/reading-icon-default.svg";
      this.loadReading = false;
    }
  }
}
