import { Injectable } from "@angular/core";
import { Observable, of, take, firstValueFrom, tap, catchError, throwError } from 'rxjs';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { UserService } from '../user/user.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AvailableAppsService } from "./available-apps.service";
import { AppNavItem } from "../../shared/app-nav-item-model";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  private licensedApplications = new Map<string, AppNavItem[]>();

  constructor(private featureToggles: FeatureToggleService,
    private userService: UserService,
    private http: HttpClient,
    private storageService: LocalStorageService,
    private availableAppsService: AvailableAppsService) { }

  _regionalApps = {
    'FL': ['APPS_FLSSP']
  } as const;


  async isDegradedMode(): Promise<boolean> {
    await this.featureToggles.isReady;
    return this.featureToggles.isTrue('force-fail-open');
  }

  async getLicensedApplications(): Promise<AppNavItem[]> {
    const degraded = await this.isDegradedMode();

    if (degraded) {
      // TODO filter to region code on the jwt
      const userRegion = this.userService.getRegion();
      const regionalApps = Object.values(this._regionalApps).flat(1) as string[];
      const nonRegionalApps = this.availableAppsService
        .getAllAvailableApps()
        .filter(app => !regionalApps.includes(app.appCode));

      let failOpenApps = nonRegionalApps.slice();
      if (userRegion in this._regionalApps) {
        const appsInUserRegion = (this._regionalApps as any)[userRegion] ?? [];
        failOpenApps = failOpenApps.concat(appsInUserRegion)
      }

      return failOpenApps;
    } 
    else {
      return await this.getAppNav();
    }
  }

  async getAppNav(): Promise<AppNavItem[]> {
    let accessToken = this.storageService.getItem('token');

    if (!accessToken) {
      throw new Error('Token missing');
    }
    let headers = new HttpHeaders({
      'access-token': accessToken
    });

    let url = this.userService.getAppNavUrl();

    if (this.licensedApplications.has(url)) {
      return this.licensedApplications.get(url) as AppNavItem[];
    }

    try {
      const data = await this.http.get<AppNavItem[]>(url, { headers }).toPromise();
      this.licensedApplications.set(url, data!);
      return data!;
    } catch (err: any) {
      if (err.status === 401) {
        throw new Error('HTTP Status 401 - Unauthorized');
      }
      throw new Error('Unknown Error: ' + err.message);
    }
  }

  isApplicationRestricted(appTagId: string): any {
    throw new Error('not implemented');
  }
}

