import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, EventEmitter, NgZone, inject } from '@angular/core';
import { KeepaliveSvc } from '@ng-idle/core';
import { firstValueFrom, take } from 'rxjs';
import { UserService } from '../services/user/user.service';

export class KeepaliveResponse {
  constructor(public status: number, public response: string) { }
}

class VanillaRequest {
  constructor(public method: string, public url: string) { }

  private _generateRequest(method: string, uri: string) {
    const request = new XMLHttpRequest();
    request.open(method, uri);
    request.responseType = 'text';
    return request;
  }

  public run(cb: (response: KeepaliveResponse) => void, error: (error: KeepaliveResponse) => void) {
    const request = this._generateRequest(this.method, this.url);

    request.onload = () => {
      cb(new KeepaliveResponse(request.status, request.response));
    };
    request.onerror = () => {
      error(new KeepaliveResponse(request.status, request.response));
    };

    request.send();
  }
}

@Injectable({
  providedIn: 'root'
})
export class KeepaliveService extends KeepaliveSvc implements OnDestroy {
  private _pingRequest!: VanillaRequest;
  private pingInterval: number = 10 * 60;
  private pingHandle: any;

  /*
   * An event emitted when the service is pinging.
   */
  public onPing: EventEmitter<any> = new EventEmitter();

  /*
   * An event emitted when the service has pinged an HTTP endpoint and received a response.
   */
  public onPingResponse: EventEmitter<KeepaliveResponse> = new EventEmitter<KeepaliveResponse>();

  /*
   * Initializes a new instance of Keepalive
   * @param http - The HTTP service.
   */
  readonly httpClient = inject(HttpClient);
  readonly zone = inject(NgZone);
  readonly userService = inject(UserService);
  
  constructor() {
    super();
  }

  /*
   * Sets the string or Request that should be used when pinging.
   * @param url - The URL or Request object to use when pinging.
   * @return The current Request used when pinging.
   */
  request<T>(url?: string): any {
    if (url) {
      this._pingRequest = new VanillaRequest('GET', url);
      return this._pingRequest;
    }
  }

  /*
   * Sets the interval (in seconds) at which the ping operation will occur when start() is called.
   * @param seconds - The ping interval in seconds.
   * @return The current interval value.
   */
  interval(seconds?: number): number {
    if (seconds && seconds > 0) {
      this.pingInterval = seconds;
    } else if (seconds && seconds <= 0) {
      throw new Error('Interval value must be greater than zero.');
    }

    return this.pingInterval;
  }

  /*
   * Immediately performs the ping operation. If a request has been set, an HTTP
   * request will be made and the response will be emitted via the
   * onPingResponse event.
   */
  async ping(): Promise<void> {
    this.onPing.emit(null);
    if (this._pingRequest) {
      this._pingRequest.run(
        (response) => {
          this.onPingResponse.emit(response);
        },
        (error) => {
          this.onPingResponse.emit(error);
        }
      );
    }

    firstValueFrom(this.httpClient.get(this.userService.getSessionKeepAliveUrl()).pipe(take(1)));
  }

  /*
   * Starts pinging on an interval.
   */
  start(): void {
    this.stop();

    this.zone.runOutsideAngular(() => {
      this.pingHandle = setInterval(() => {
        this.zone.run(() => {
          this.ping();
        });
      }, this.pingInterval * 1000);
    });
  }

  /*
   * Stops pinging on an interval.
   */
  stop(): void {
    if (this.hasPingHandle()) {
      clearInterval(this.pingHandle);
      this.pingHandle = null;
    }
  }

  /*
   * Performs any cleanup tasks when Angular destroys the instance.
   */
  ngOnDestroy(): void {
    this.stop();
  }

  /*
   * Returns whether or not the service will ping automatically at the specified interval.
   * @return True if the service will ping at the specified interval; otherwise, false.
   */
  isRunning(): boolean {
    return this.hasPingHandle();
  }

  private hasPingHandle(): boolean {
    return this.pingHandle !== null && typeof this.pingHandle !== 'undefined';
  }
}
