export const Language = {
  EnUs: 'en-US',
  EnGb: 'en-GB',
} as const;

export enum AppSection {
  Practice = "practice",
  Assessment = "assessment",
  Saebrs = "saebrs",
  StatePortal = "state",
  Unknown = "unknown"
}

export enum AppSubSection {
  English = "english",
  Spanish = "spanish",
  CBM = "cbm",
  None = "none"
}

export enum TakenAt {
  School = "SCHOOL",
  Away = "AWAY",
  NotApplicable = "NA"
}

export enum HelpId {
  HomePageEnUs = 'en-us-help-setup-22521',
  HomePageSuffix = '-help-setup-22521'
}

export enum ErrorModalTitles {
  Freckle = 'Freckle',
  Lalilo = 'Lalilo'
}

export type ErrorModalTypes = 'freckle' | 'lalilo';

export const ErrorModalNames: readonly ErrorModalTypes[] = ['freckle', 'lalilo'];

export const ErrorModalAppTags = {
  lalilo: 'APPS_LALILO',
  freckle: 'APPS_FRECKLE'
}

export enum LaunchErrorMessages {
  GenericFailure = 'L10N.LAUNCHERROR.GENERIC',
  FailedCapacityCheck = 'L10N.LAUNCHERROR.CAPACITY',
  UnknownError = 'L10N.LAUNCHERROR.UNKNOWN'
}

export enum LaunchRestrictionModalTypes {
  GenericFailure = 'genericFailure',
  FailedCapacityCheck = 'failedCapacityCheck'
}

export const StarStudentDiscoveryToken = '$star-student-discovery$';
export const StarCBMToken = '$star-cbm$';
export const StatePortalFloridaToken = '$state-portal-florida$';

export const ArArticleQuizTypes: readonly string[] = [
  'ASNTYP_AR360ARQ',
  'ASNTYP_AR360ASPQ',
  'ASNTYP_AR360ARA'
];

export const ArQuizTypes: readonly string[] = [
  'ASNTYP_ARRP',
  'ASNTYP_ARLS',
  'ASNTYP_AROR',
  'ASNTYP_ARVP'
];
