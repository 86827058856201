export class ProductAppCodes {
    public static StarReading: string = 'APPS_SR';    
    public static StarMath: string = 'APPS_SM';    
    public static StarEarlyLiteracy: string = 'APPS_SEL';    
    public static StarReadingSpanish: string = 'APPS_SRS2';    
    public static StarMathSpanish: string = 'APPS_SMS';    
    public static StarEarlyLiteracySpanish: string = 'APPS_SELS';
    public static StarCbm: string = 'APPS_CBM';
    public static FreckleMath: string = 'APPS_FRMATH';
    public static Myon: string = 'APPS_MYON';
    public static Lalilo: string = 'APPS_LALILO';
    public static StarPhonics: string = 'APPS_PHONICS';
    public static Saebrs: string = 'APPS_SAEBRS';    
  }