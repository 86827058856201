import { Injectable } from '@angular/core';
import { Idle, AutoResume, DocumentInterruptSource } from '@ng-idle/core';
import { IdleTimeoutConfig,IdleTimeoutConstants } from './idle-timeout.config'
import { KeepaliveService } from './keepalive.service';
import { WindowService } from './window.service';
import { TimeoutModalService } from './timeout-modal.service';
import { HttpResponse } from '@angular/common/http';


export const CalculateMintutesLeftFromSeconds = (countdown: number) => Math.ceil(countdown / 60).toFixed(0).toString();

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  private static _inframe = false;
  private static _configured = false;

  constructor(
    private idleService: Idle,
    private keepaliveService: KeepaliveService,
    private window: WindowService,
    private modal: TimeoutModalService) {

    if (IdleTimeoutService._configured) {
      this.watch();
    }
  }

  private static _config: IdleTimeoutConfig = {
    keepaliveUrl: '',
    logoutUrl: '',
    keepaliveIntervalMinutes: IdleTimeoutConstants.DefaultKeepaliveInterval,
    timeoutMinutes: null,
    timeToRespondMinutes: IdleTimeoutConstants.DefaultTimeToRespondMinutes,
    debug: false
  };

  public static configure(config: IdleTimeoutConfig) {
    IdleTimeoutService._config = {...IdleTimeoutService._config, ...config};
    // If timeout set lower than default time to respond, use half the timeout
    if (IdleTimeoutService._config.timeoutMinutes &&
        IdleTimeoutService._config.timeoutMinutes <= IdleTimeoutConstants.DefaultTimeToRespondMinutes) {
      IdleTimeoutService._config.timeToRespondMinutes = IdleTimeoutService._config.timeoutMinutes / 2;
    }

    // If keepalive interval is not specified default to 15 minutes
    if (!IdleTimeoutService._config.keepaliveIntervalMinutes) {
      IdleTimeoutService._config.keepaliveIntervalMinutes = IdleTimeoutConstants.DefaultKeepaliveInterval;
    }

    // If logout or keepalive url is not specified explode
    if (!IdleTimeoutService._config.logoutUrl) {
      try {
        console.error('Logout URL not specified!');
      } catch {}
    }

    if (!IdleTimeoutService._config.keepaliveUrl) {
      try {
        console.error('Keepalive URL not specified!');
      } catch {}
    }

    IdleTimeoutService._configured = true;
    try {
        // iframe detection, we do not want to do anything in an iframe
        // the top window is responsible for the timeout
        IdleTimeoutService._inframe = window.top !== window;
    } catch {}
  }  

  public watch() {
    if (IdleTimeoutService._inframe) {
      if (IdleTimeoutService._config.debug) { console.log('Inside iframe idle timeout suspended'); }
      return;
    }

    if (IdleTimeoutService._configured) {

      // If the client does not have timeout set we do nothing here
      if (IdleTimeoutService._config.timeoutMinutes) {
          this.idleService.setIdle((IdleTimeoutService._config.timeoutMinutes - IdleTimeoutService._config.timeToRespondMinutes) * 60);
          this.idleService.setTimeout(IdleTimeoutService._config.timeToRespondMinutes * 60);
          this.idleService.setAutoResume(AutoResume.notIdle);
          this.idleService.setInterrupts([
            new DocumentInterruptSource('mousemove keydown DOMMouseScroll mousewheel touchstart touchmove scroll')]);

          // Configure timeout modal
          this.modal.onLogout(() => this.logout());
          this.modal.onReturnToScreen(() => this.interrupt());
          this.onIdleStart(() => {
            this.modal.open(IdleTimeoutService._config.timeToRespondMinutes.toString());
          });
          this.onIdleWarn((countdown) => {
            this.modal.updateWarningInterval(CalculateMintutesLeftFromSeconds(countdown));
          });
      }

      // Regardless of timeout, keep alive will always be running
      this.keepaliveService.interval(IdleTimeoutService._config.keepaliveIntervalMinutes * 60);
      this.keepaliveService.request(IdleTimeoutService._config.keepaliveUrl);

      if (IdleTimeoutService._config.debug) {
          this.idleService.onIdleStart.subscribe(() => {
              console.log('Idle... counting down from: ' + IdleTimeoutService._config.timeToRespondMinutes * 60);
          });

          this.idleService.onTimeoutWarning.subscribe(countdown => {
              console.log('Idle... time left: ' + countdown);
          });

          this.idleService.onIdleEnd.subscribe(() => {
              console.log('No longer idle');
          });
      }

      // Desired timeout behavior
      this.idleService.onTimeout.subscribe(() => {
          this.logout();
      });

      this.keepaliveService.onPingResponse.subscribe((response: HttpResponse<any>) => {
        if (response.status === 200) {
          if (IdleTimeoutService._config.debug) { { console.log('Keep Alive Session Expired, Reloading'); } }
          this.window.refresh();
        }

        if (response.status === 204 && IdleTimeoutService._config.debug) {
          console.log('Keep Alive Success');
        }
      });

      // Start watching for idleness
      if (this.idleService) { this.idleService.watch(); }
      if (this.keepaliveService) { this.keepaliveService.start(); }
    } else {
        // Not configued
        throw new Error('Idle timeout not configured');
    }
  }

  public interrupt() {
    if (IdleTimeoutService._config.debug) { console.log('User interrupt'); }
    if (this.keepaliveService) {
      this.keepaliveService.ping();
    }
    if (this.idleService) {
      this.idleService.watch();
    }
  }

  public logout() {
    if (IdleTimeoutService._config.debug) { console.log('Log out'); }
    this.window.navigate(IdleTimeoutService._config.logoutUrl);
  }

  /**
   * Called when user idleness is detected, wire up any user warning code here,
   * like modals/popups/messages etc.
   * @param callback
   */
  public onIdleStart(callback: () => void): void {
    this.idleService.onIdleStart.subscribe(callback);
  }

  /**
   * Called when user idleness is detected with a countdown, wire up any user warning code that needs a countdown here.
   * @param callback
   */
  public onIdleWarn(callback: (countdownSeconds: number) => void): void {
    this.idleService.onTimeoutWarning.subscribe(callback);
  }

  /**
   * Called when a user has resumed interacting with the page, wire up and application resume code here
   * @param callback
   */
  public onIdleEnd(callback: () => void): void {
    this.idleService.onIdleEnd.subscribe(callback);
  }

  /**
   * Called directly before redirecting the user to their appropriate logout url
   * @param callback
   */
  public onIdleTimeout(callback: () => void): void {
    this.idleService.onTimeout.subscribe(callback);
  }
}
