import { Injectable } from '@angular/core';
import { FreckleSkill } from '../../shared/models/freckle-skill';
import { FreckleSkillResult } from '../../shared/models/freckle-skill-result';
import { firstValueFrom, Observable, of, take } from 'rxjs';
import { FreckleSkillProcessorService } from './freckle-skill-processor.service';
import { LearningJourneyService } from '../learning-journey/learning-journey.service';

@Injectable({
  providedIn: 'root'
})
export class FreckleSkillService {

  constructor(private readingSkillProcessorService : FreckleSkillProcessorService,
    private learningJourneyService: LearningJourneyService) { }

  async getReadingSkillsByMonth(month: number, year: number): Promise<FreckleSkill[]> {
    let studentJourneyData = await this.learningJourneyService.getStudentJourneyData();
    
    
    let currentSelectedStudentData = studentJourneyData.find(x => {
      let yearMonthSplit =  x.schoolYearMonth.split('+');
      const dataMonth = Number(yearMonthSplit[1]);
      const dataYear = Number(yearMonthSplit[0]);

      return month == dataMonth && year == dataYear
    });

    if (currentSelectedStudentData == null) return [];

    let ReadingStudentJourneyData = currentSelectedStudentData.freckleSkills.filter(x => x.contentArea === 'ela');
    
    return ReadingStudentJourneyData;
  }

  async getAllReadingSkills(): Promise<FreckleSkill[]> {
    let studentJourneyData = await this.learningJourneyService.getStudentJourneyData();
    let readingStudentJourneyData: FreckleSkill[] = [];
    studentJourneyData.forEach(x => {
      readingStudentJourneyData = [...readingStudentJourneyData, ...x.freckleSkills.filter(y => y.contentArea === 'ela')];
    });

    return readingStudentJourneyData;
  }

  async getMathSkillsByMonth(month: number, year: number): Promise<FreckleSkill[]> {
    let studentJourneyData = await this.learningJourneyService.getStudentJourneyData();
    
    
    let currentSelectedStudentData = studentJourneyData.find(x => {
      let yearMonthSplit =  x.schoolYearMonth.split('+');
      const dataMonth = Number(yearMonthSplit[1]);
      const dataYear = Number(yearMonthSplit[0]);

      return month == dataMonth && year == dataYear
    });

    if (currentSelectedStudentData == null) return [];

    let mathStudentJourneyData = currentSelectedStudentData.freckleSkills.filter(x => x.contentArea === 'math');
    
    return mathStudentJourneyData;
  }

  async getAllMathSkills():  Promise<FreckleSkill[]> {
    let studentJourneyData = await this.learningJourneyService.getStudentJourneyData();
    let mathStudentJourneyData: FreckleSkill[] = [];
    studentJourneyData.forEach(x => {
      mathStudentJourneyData = [...mathStudentJourneyData, ...x.freckleSkills.filter(y => y.contentArea === 'math')];
    });

    return mathStudentJourneyData;
  
  }

  processFreckleSkills(freckleSkills: FreckleSkill[]): FreckleSkillResult {
    const result = new FreckleSkillResult();

    freckleSkills.forEach(skill => this.readingSkillProcessorService.processSkill(skill, result));

    this.readingSkillProcessorService.sortSkillsByLastPracticed(result);

    result.timeSpent = this.readingSkillProcessorService.convertMinutesToTimeString(result.totalMinutes);

    result.hasLargeGroup = [
      result.needHelp.skills,
      result.practicing.skills,
      result.mastered.skills
    ].some(group => group.length > 3);

    return result;
  }
}
