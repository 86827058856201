<nav class="timeline-picker-container" aria-label="Month picker">
  <div class="timeline-picker">
    <label for="timeline-select" class="label">
      Month:
    </label>
    <div class="line"></div>
    <div class="picker">
      <select name="timeline" id="timeline-select" class="timeline-select" (change)="onMonthChange($event)">
        <option *ngFor="let month of timelineMonths" [value]="month.order" [selected]="selectedMonth?.order == month?.order">{{month.label}}</option>
      </select>
    </div>
  </div>
</nav>
