import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, ParamMap, Router } from "@angular/router";
import { FeatureToggleService } from "./services/feature-toggle/feature-toggle.service";
import { JwtParserService } from "./services/jwt/jwt-parser.service";
import { LocalStorageService } from "./services/local-storage/local-storage.service";
import { UserService } from "./services/user/user.service";
import { InvalidTokenError } from "jwt-decode";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppRoutingGuard {
  private window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private toggleService: FeatureToggleService,
    private jwtParser: JwtParserService,
    public userService: UserService,
    private storageService: LocalStorageService
  ) {
    this.window = this.document.defaultView as Window;
  }

  /**
   * Checks if the JWT is valid and initializes the app if it is.
   * If the JWT came from the url, it will be removed from the url, stored in
   * localStorage, and the app will be reloaded.
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return await this.parseJwtAndLogin(route.queryParamMap)
  }

  async parseJwtAndLogin(paramMap: ParamMap): Promise<boolean> {
    let jwt = paramMap.get('jwt');
    let validJwt = false

    if (jwt) {
      validJwt = await this.validateToken(jwt);
      if (!validJwt) return false

      // Store the jwt in localStorage
      this.storageService.setItem('token', jwt);

      // Remove the jwt from the url and reload the page
      const queryParams = this.removeParamFromUrl(paramMap, ['jwt']);
      this.router.navigate([], { queryParams, replaceUrl: true });
    } else {
      // Get jwt from localStorage
      jwt = this.storageService.getItem('token') || 'invalid_jwt';

      validJwt = await this.validateToken(jwt);
    }

    return validJwt;
  }

  /**
   * Validates the JWT and initializes the app if the JWT is valid.
   * InvalidTokenError (missing or malformed jwt) = Redirect to expiredJwtRedirectUrl
   * Expired JWT = Redirect to homeuri
   */
  async validateToken(jwt: string): Promise<boolean> {
    try {
      const decoded = await this.jwtParser.decode(jwt);
      await this.toggleService.initialize(decoded.clientid);

      //We must redirect back to studentportal to get a valid jwt
      if (await this.jwtParser.isValidAndExpired(jwt)) {
        this.window.location = decoded.homeuri

        return false
      }

      if (!(await this.jwtParser.verify(jwt))) {
        this.window.location = environment.invalidJwtRedirectUrl;

        return false
      }

      this.userService.setUserSession(jwt);
      return true
    } catch (error: any) {
      if (error instanceof InvalidTokenError) {
        console.error('Error validating JWT: ', error.message);
      }

      this.window.location = environment.invalidJwtRedirectUrl;

      return false
    }
  }

  /**
   * Removes the specified keys from the url and navigates to the new url.
   * Used to remove the params from the url after they have been processed.
   */
  private removeParamFromUrl(paramMap: ParamMap, keysToRemove: string[]): {} {
    const queryParams = {} as any;
    const keysToKeep = paramMap.keys.filter(k => !keysToRemove.includes(k));
    keysToKeep.forEach(k => (queryParams[k] = paramMap.get(k)));

    return queryParams
  }
}
