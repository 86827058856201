<div class="reading-container">
    <div class="reading-box with-line">
        <img src="../../assets/freckle-corkboard-sticker.svg" alt="" class="reading-corner-image" />
        <h2 class="reading-section-header"> I practiced <strong>2 hours and 60 Minutes</strong> in Freckle.</h2>
        <div class="line"></div>
        <div class="reading-content-skills">
            <div class="reading-header-row">
                <img src="../../assets/ready-for-more.svg" alt="" class="reading-practise-image" />
                <h3 class="reading-sub-section-header">I’m am realy good at:</h3>
            </div>
            <ul class="reading-bullet">
                <li>Use Details & Make Inferences</li>
                <li>Summarize Texts & Determine Main Idea</li>
                <li>Understand Synonyms & Antonyms</li>
            </ul>
            <div class="reading-header-row">
                <img src="../../assets/appropriate-level.svg" alt="" class="reading-practise-image" />
                <h3 class="reading-sub-section-header">I’m practicing:</h3>
            </div>
            <ul class="reading-bullet">
                <li>Distinguish Points of View</li>
                <li>Use Relative Pronouns & Adverbs</li>
            </ul>
            <div class="reading-header-row"> 
                <img src="../../assets/helping-hand.svg" alt="" class="reading-practise-image" />
                <h3 class="reading-sub-section-header">I might need help on:</h3>
            </div>
            <ul class="reading-bullet">
                <li>Explain Relationships Between Events & Ideas</li>
            </ul>
        </div>
    </div>
    <div class="reading-box">
        <img src="../../assets/star-corkboard-sticker.svg" alt="" class="reading-corner-image" />
        <h3 class="reading-section-header"> I took a Reading test on December 3rd.</h3>
    </div>
    <div class="reading-ar-box">
        <img src="../../assets/ar-corkboard-sticker.svg" alt="" class="reading-corner-image" />
        <h3 class="reading-section-header"> Quizzes I Finished</h3>
    </div>
</div>