import { Injectable } from '@angular/core';
import { Future } from '../../util/future';
import { JwtParserService, RenaissanceJWT } from '../jwt/jwt-parser.service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    private _jwt: RenaissanceJWT | null = null;
    private _clientId: string | null = null;
    private _userId: string | null = null;
    private _expiry: Date | null = null;
    private _rawJwt: string = '';
    private _sessionKeepAliveUrl: string = '';
    private _homeUri: string = '';
    private _logoutUri: string = '';
    private _isLoaded: Future<boolean> = new Future<boolean>();
    private _isLoggedIn: Future<boolean> = new Future<boolean>();
    private _timeoutMinutes: number = 30; // Default timeout minutes
    private _keepAliveIntervalMinutes: number = 15; // Default keep alive interval minutes
    private _featureset: string = '';
    private _region: string = '';
    private _country: string = '';
    private _culture: string = '';
    private _appNavUrl: string | null = null;
    private _learningJourneyUrl: string | null = null;
    private _firstName: string | null = null;
    private _lastName: string | null = null;

    constructor(private jwtParser: JwtParserService) {
        this.isLoggedIn();
    }

    getLogoutUri(): string {
        return this._logoutUri;
    }

    async setUserSession(rawJwt: string) {
        const jwt = await this.jwtParser.decode(rawJwt);
        if (jwt) {
            this._rawJwt = rawJwt
            this._jwt = jwt;
            this._clientId = jwt.clientid;
            this._userId = jwt.rid;
            this._expiry = new Date(1000 * jwt.exp);
            this._homeUri = jwt.homeuri;
            this._logoutUri = jwt.logouturi;
            this._timeoutMinutes = jwt.timeoutminutes;
            this._keepAliveIntervalMinutes = jwt.keepaliveintervalminutes;
            this._culture = jwt.culture;
            this._country = jwt.country;
            this._region = jwt.region;
            this._featureset = jwt.featureset;
            this._isLoggedIn.resolve(true);
            this._firstName = jwt.given_name;
            this._lastName = jwt.family_name;
        }
        if (jwt.globalbase) {
            // TODO switch to SJ adapter when it exists
            this._sessionKeepAliveUrl = `${jwt.globalbase}teacherjourneyadapter/Session/KeepAlive`
            this._appNavUrl = `${jwt.globalbase}studentjourneyadapter/AppNav`;
            this._learningJourneyUrl = `${jwt.globalbase}studentjourneyadapter/LearningJourney`;
        }

        this._isLoaded.resolve(true);
    }

    async isLoaded(): Promise<boolean> {
        return await this._isLoaded.promise;
    }

    getClientId() {
      return this._clientId;
    }

    getUserId() {
      return this._userId;
    }

    getTimeoutMinutes(): number {
        return this._timeoutMinutes;
    }

    getZoneUri(): string {
        return new URL(this._homeUri).origin;
    }

    getZone(): string {
        let homeUri = this._jwt!.homeuri;
        let index = homeUri.indexOf('-');
        let indexEnd = homeUri.indexOf('.');
        let zone = homeUri.substring(index + 1, indexEnd);
        return zone;
    }

    getKeepAliveIntervalMinutes(): number {
        return this._keepAliveIntervalMinutes;
    }

    getSessionKeepAliveUrl(): string {
        return this._sessionKeepAliveUrl;
    }

    getCulture(): string {
        return this._culture;
    }

    getRegion(): string {
        return this._region;
    }

    getFeatureSet(): string {
        return this._featureset;
    }

    getCountry(): string {
        return this._country;
    }

    isLoggedIn(): Promise<boolean> {
        return this._isLoggedIn.promise;
    }

    getHomeUri(): string {
        return this._homeUri;
    }

    getAppNavUrl(): string {
        return this._appNavUrl ?? environment.appNavUrl;
    }

    getLearningJourneyUrl(): string {
      return this._learningJourneyUrl ?? environment.learningJourneyUrl;
  }

    getRgpBaseUrl(): string {
        let homeUri = this._jwt!.homeuri;
        let idx = homeUri!.indexOf('studentportal');
        let baseUrl = homeUri.substring(0, idx);
        return baseUrl;
    }

    getStudentJourneyDataApiUri(): string {
        return environment.studentJourneyDataApiUri;
    }

    getFullName() {
        return this._firstName + ' ' + this._lastName;
    }

    getFirstName() {
        return this._firstName;
    }
}
