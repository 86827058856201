<div class="page">
<div class="left-padding">
</div>
<div class="learning-container">
    <div class="sidebar">
      <app-timeline *ngIf="schoolYearMonths.length > 0"
        [allYear]="allYear"
        [timelineMonths]="schoolYearMonths"
        [selectedMonth]="currentMonth"
        (monthChangeEvent)="onMonthChangeEvent($event)"></app-timeline>
    </div>
    <div class="content">
        <div class="top-nav">
            <div class="timeline-picker">
              <app-timeline-picker *ngIf="schoolYearMonths.length > 0"
                [allYear]="allYear"
                [timelineMonths]="schoolYearMonths"
                [selectedMonth]="currentMonth"
                (monthChangeEvent)="onMonthChangeEvent($event)"></app-timeline-picker>
            </div>
            <div *ngIf="showTopNav" class="buttons">
                <button class="top-nav-button" [ngClass]="{ 'active': activeIndex === 0 }" (click)="toggleActive(0)">
                    <img class="icon" [src]="readingImage" alt="" />
                    <div class="text">
                        Reading
                    </div>
                </button>
                <div class="divider"></div>
                <button class="top-nav-button" [ngClass]="{ 'active': activeIndex === 1 }" (click)="toggleActive(1)">
                    <img class="icon" [src]="mathImage" alt="" />
                    <div class="text">
                        Math
                    </div>
                </button>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
<div class="right-padding">
</div>
</div>
