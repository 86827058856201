export interface IdleTimeoutConfig {
    keepaliveUrl: string;
    logoutUrl: string;
    keepaliveIntervalMinutes: number;
    timeoutMinutes: number | null;
    timeToRespondMinutes: number;
    debug?: boolean;
}

export class IdleTimeoutConstants {
    static DefaultTimeoutMinutes = 30; // 30 minutes
    static DefaultTimeToRespondMinutes = 5; // 5 minutes
    static DefaultKeepaliveInterval = 15; // 15 minutes
  }